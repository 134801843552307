import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter as HashRouter, Route, Switch } from "react-router-dom";
import React, { Component } from "react";
import { ToastContainer } from "react-toastify";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const CustomLogin = React.lazy(() => import("./views/customs/Login"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <CustomLogin {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
          </Switch>
          <ToastContainer />
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
